<template>
  <b-card title="Auditoria - Registro de actividades">
    <b-row>
      <b-col
        md="3"
      >
        <b-button variant="primary" @click="actualizarLista()" class="mr-1">
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
          />
          <span class="align-middle">Actualizar</span>
        </b-button>

      </b-col>

      <b-col md="3">
        <v-select
          label="title"
          placeholder="Filtrar por Registro"
          v-model="logName_id"
          :options="logNameOpciones"
        />
      </b-col>
 
      <b-col md="3" class="mb-1">
        <v-select
          label="title"
          placeholder="Filtrar por Usuario"
          v-model="causer_id"
          :options="causerOpciones"
        />
      </b-col>
      <b-col
        md="3"
      >
        <b-form-group
          label=""
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              @keyup.enter="onFiltered()"
              placeholder="Buscar.."
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="my-1">
        <b-overlay
          :show="loading"
        >
          <b-table class="sm" responsive="sm" 
              ref="selectableTable"
              hover
              :small="true"
              :items="items" 
              :fields="fields"
              show-empty
              empty-text="No se encontraron registros coincidentes"
              >
              <template #cell(description)="data">
                <b-badge :variant="description[1][data.value]">
                  {{ description[0][data.value] }}
                </b-badge>
              </template>

              <template #cell(accion)="data">
                <b-button
                  v-b-modal.modal-view-detalle-auditoria @click="abriComponente(data.item)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="btn-icon mr-1"
                  size="sm"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </template>

          </b-table>
        </b-overlay>
      </b-col>
      <b-col cols="12">
          <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    <Detalle :auditoriaId="auditoriaId" :item="item" @cerrarComponente="cerrarComponente"></Detalle>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import { BCard, BCardText, BTable, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BDropdown, BDropdownItem, BOverlay, VBTooltip } from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import Detalle from '@/views/configuracion/auditoria/Detalle.vue';

export default {
    components: {
        BCard,
        BCardText,
        BTable,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BDropdown,
        BDropdownItem,
        BOverlay,
        Detalle,
        vSelect
    },
    directives: {
      Ripple,
      'b-tooltip': VBTooltip,
    },
    data(){
        return{
              items:[],
              fields: [
                  { key: 'created_at', label: 'Fecha', sortable: false },
                  { key: 'time', label: '', sortable: false },
                  { key: 'log_name', label: 'Registro', sortable: false },
                  { key: 'subject_type', label: 'Modelo', sortable: false },
                  { key: 'subject_id', label: 'Modelo ID', sortable: false },
                  { key: 'description', label: 'Metodo', sortable: false },
                  { key: 'causer.name', label: 'Usuario', sortable: false },
                  'accion',
              ],
              filter: '',
              filterOn: [],
              perPage: 10,
              pageOptions: [10, 25, 50],
              totalRows: 1,
              currentPage: 1,
              accion:'',
              loading:false,
              description: [{
                'created': 'Registro', 'updated': 'Actualizado', 'deleted': 'Eliminado',
              },
              {
                'created': 'light-primary', 'updated': 'light-warning', 'deleted': 'light-danger',
              }],
              item:[],
              auditoriaId:'',
              causerOpciones:[],
              causer_id:'',
              logNameOpciones:[],
              logName_id:'',
        }
    },
    watch: {
      currentPage: function() {
        if (!this.loading) {
          this.loading = true;
          this.listar();
        }
      },
      filter: function(val) {
          if (val == "" || val.length == 0) {
            this.loading=true
            this.listar();
          }
      },
      causer_id: function() {
        this.loading = true;
        this.listar();
      },
      logName_id: function() {
        this.loading = true;
        this.listar();
      },
    },
    created(){        
        this.loading=true       
        this.listar();
        this.listarCauser();
        this.listarLogName();
    },  
    methods: {
        onFiltered(filteredItems) {
          if (this.currentPage!=1) {
            this.currentPage=1;
          }else{
            this.loading=true
            this.listar();
          }
        },
        listar(){
            let causer="";
            let logName="";
            if (this.causer_id==null || this.causer_id=="" || this.causer_id=='undefined'){causer="";}else{causer=this.causer_id.value;}
            if (this.logName_id==null || this.logName_id=="" || this.logName_id=='undefined'){logName="";}else{logName=this.logName_id.value;}
            var url= '/admin/auditorias?page='+this.currentPage + '&causer='+causer + "&logName=" + logName + "&buscar=" + this.filter;
            axiosIns.get(url)
            .then(res => {
              this.items=res.data.data;
              this.totalRows = res.data.total
              this.loading=false
            })
            .catch(err =>{
                console.log(err);
            });
        },
        listarCauser(){
            var url= '/admin/auditorias/causer';
            axiosIns.get(url)
            .then(res => {
                this.causerOpciones=res.data.map(g => ({title:g.causer.name, value: g.causer_id}));
            })
            .catch(err =>{
                console.log(err);
            });
        },
        listarLogName(){
            var url= '/admin/auditorias/log-name';
            axiosIns.get(url)
            .then(res => {
                this.logNameOpciones=res.data.map(g => ({title:g.log_name, value: g.log_name}));
            })
            .catch(err =>{
                console.log(err);
            });
        },
        actualizarLista(){
          this.loading=true;
          this.listar();
          this.listarCauser();
          this.listarLogName();
          this.auditoriaId='';
          this.item=[];
        },
        abriComponente(item){
          this.auditoriaId=item.id;
          this.item=item;
        },
        cerrarComponente(){
          this.auditoriaId='';
          this.item=[];
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>