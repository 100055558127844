<template>
    <b-modal
        id="modal-view-detalle-auditoria"
        ref="my-modal"
        :title="'Detalle de Auditoria - Modelo '+ item.log_name"
        scrollable
        size="xl"
    >
        <b-row class="match-height">
            <b-col cols="12">
                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <div>
                    <div>
                        <h6 v-if="item.subject">
                            <p class="card-text mb-25" v-if="item.subject.nombre">
                                <strong>Nombre (Actual) : </strong>{{item.subject.nombre}}
                            </p>
                            <p class="card-text mb-25" v-if="item.subject.titulo">
                                <strong>Titulo (Actual) : </strong> {{item.subject.titulo}}
                            </p>
                            <p class="card-text mb-25" v-if="item.subject.name">
                                <strong>Nombre (Actual) : </strong> {{item.subject.name}}
                            </p>
                            <p class="card-text mb-25" v-if="item.subject.tipo_entrada_id">
                                <strong>Tipo de Entrada (Actual) : </strong> {{tipo_entrada}}
                            </p>
                        </h6>
                        <h6 v-else class="text-danger px-1">
                            No se encontro el registro! (Eliminado).
                        </h6>
                    </div>
                </div>
                <div class="mt-md-0 mt-2">
                    <h6 :class="description[1][item.description]">
                        {{ description[0][item.description] }}
                    </h6>
                    <h6 class="invoice-title">
                        ID : <span class="invoice-number">{{item.subject_id}}</span>
                    </h6>
                    <h6 v-if="item.causer" class="invoice-title">
                        <span class="invoice-number">{{item.causer.name}}</span>
                    </h6>
                </div>
                </div>
            </b-col>
            <b-col cols="12">
                <table class="table table-bordered min-w-full divide-y divide-gray-200 dark:divide-gray-800 dark:bg-green-400 " >
                    <thead>
                        <tr>
                            <th scope="col" class="px-2 py-2 text-xs font-medium tracking-wider text-left uppercase dark:bg-black dark:text-gray-300">
                                REGISTRO
                            </th>
                            <th scope="col" class="px-2 py-2 text-xs font-medium tracking-wider text-left uppercase dark:bg-black dark:text-gray-300">
                                DETALLE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(detalle,key,index) in item.properties" :key="index">
                            <td class="px-2 py-2 font-medium">
                                <span class="text-primary" v-if="key=='attributes'">
                                    <strong v-if="item.description=='deleted'" class="text-danger">ELIMINADO</strong>
                                    <strong v-else>NUEVO</strong>
                                </span>
                                <span class="text-danger" v-if="key=='old'"><strong>ANTERIOR</strong></span>
                            </td>
                            <td class="px-2 py-2">
                                
                                <p v-for="(det,key1,index1) in detalle" :key="index1">
                                    <span v-if="index1>=0">
                                        <strong> {{key1}} : </strong> <span v-html="det"></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="key1>0"><hr></span>
                                        <p v-for="(det1,key2,index2) in det" :key="index2">
                                            <span 
                                                v-if="key2!='id' && key2!='guard_name' && key2!='created_at' && key2!='updated_at' && key2!='pivot' && key2!='galeria_id'"
                                            >
                                                <strong> {{key2}} : </strong> <span v-html="det1"><br></span>
                                            </span>
                                        </p>
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import axiosIns from '@/libs/axios'
import { BModal, BButton, VBModal, BAlert, BFormGroup, BFormInput, BRow, BCol, BCardText, BCard } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BButton,
        BModal,
        BAlert,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BCardText,
        BCard,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    props:[
        'auditoriaId','item'
    ],
    data(){
        return{
            description: [{
            'created': 'Registro', 'updated': 'Actualizado', 'deleted': 'Eliminado',
            },
            {
            'created': 'text-primary', 'updated': 'text-warning', 'deleted': 'text-danger',
            }],
            tipo_entrada:''
        }
    },
    watch: {
        auditoriaId: function () {
            this.tipo_entrada='';
            if (this.item.subject) {
                if (this.item.subject.tipo_entrada_id) {
                   this.getTipoEntrada(); 
                }
            } 
        },
    },
    methods: {
        getTipoEntrada() {
           axiosIns.get("/admin/tipo-entradas-get/" + this.item.subject.tipo_entrada_id)
            .then(res => {
                this.tipo_entrada=res.data.nombre
            })
            .catch(err =>{
                this.tipo_entrada='No existe';
            });
        },
    }
}
</script>
<style lang="scss">
</style>